// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllShopMembersRequest = createAction('GET_ALL_SHOPMEMBERS_REQUEST');
export const getAllShopMembersSuccess = createAction('GET_ALL_SHOPMEMBERS_SUCCESS');
export const getAllShopMembersFailure = createAction('GET_ALL_SHOPMEMBERS_FAILURE');

export const createShopMemberRequest = createAction('CREATE_SHOPMEMBER_REQUEST');
export const createShopMemberSuccess = createAction('CREATE_SHOPMEMBER_SUCCESS');
export const createShopMemberFailure = createAction('CREATE_SHOPMEMBER_FAILURE');
export const resetCreateShopMember = createAction('RESET_CREATE_SHOPMEMBER');

export const updateShopMemberRequest = createAction('UPDATE_SHOPMEMBER_REQUEST');
export const updateShopMemberSuccess = createAction('UPDATE_SHOPMEMBER_SUCCESS');
export const updateShopMemberFailure = createAction('UPDATE_SHOPMEMBER_FAILURE');
export const resetUpdateShopMember = createAction('RESET_UPDATE_SHOPMEMBER');

export const deleteShopMemberRequest = createAction('DELETE_SHOPMEMBER_REQUEST');
export const deleteShopMemberSuccess = createAction('DELETE_SHOPMEMBER_SUCCESS');
export const deleteShopMemberFailure = createAction('DELETE_SHOPMEMBER_FAILURE');
export const resetDeleteShopMember = createAction('RESET_DELETE_SHOPMEMBER');

export const usePointShopMemberRequest = createAction('USE_POINT_SHOPMEMBER_REQUEST');
export const usePointShopMemberSuccess = createAction('USE_POINT_SHOPMEMBER_SUCCESS');
export const usePointShopMemberFailure = createAction('USE_POINT_SHOPMEMBER_FAILURE');
export const resetUsePointShopMember = createAction('RESET_USE_POINT_SHOPMEMBER');

export const resetShopMemberState = createAction('RESET_SHOPMEMBER_STATE');
