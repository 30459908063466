const workTime = {
  'work.time': 'Work time',
  'work.time.list': 'List work time',
  'work.time.edit': 'Edit work time',
  'work.time.delete': 'Delete work time',
  'work.time.manage': 'Manage work time',
  'work.time.create': 'Create work time',
  'work.time.create.confirm': 'Confirm create work time',
  'work.time.create.confirmSure': 'You will definitely create work time?',
  'work.time.create.success': 'Create work time successfully',
  'work.time.edit.confirm': 'Confirm edit work time',
  'work.time.edit.confirmSure': 'You will definitely edit work time?',
  'work.time.delete.confirm': 'Confirm delete work time',
  'work.time.delete.confirmSure': 'You will definitely delete work time?',
  'work.time.update.success': 'Update work time successfully',
  'work.time.delete.success': 'Delete work time successfully',
  'work.time.method': 'Method',
  'work.time.full-day': 'Full day',
  'work.time.half-day': 'Half day',
  'work.time.part-time': 'Part time',
  'work.time.next': 'Next day',
};

const usLangWorkTime = {
  ...workTime,
};

export default usLangWorkTime;
