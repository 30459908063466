/* eslint-disable import/no-extraneous-dependencies */
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* getAllShopMembers({ payload }) {
  try {
    const response = yield call(
      typeof payload === 'string'
        ? () => axiosMicro.get(`/shop-members?${payload}`)
        : () => axiosMicro.get('/shop-members', { params: payload }),
    );
    yield put(Actions.getAllShopMembersSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.getAllShopMembersFailure(messages));
    }
  }
}

function* createShopMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/shop-members', payload));
    yield put(Actions.createShopMemberSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.createShopMemberFailure(messages));
    }
  }
}

function* updateShopMember({ payload }) {
  try {
    const response = yield call(() => axiosMicro.patch(`/shop-members/${payload.id}`, payload.body));
    yield put(Actions.updateShopMemberSuccess(response.data));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.updateShopMemberFailure(messages));
    }
  }
}

function* deleteShopMember({ payload }) {
  try {
    yield call(() => axiosMicro.delete(`/shop-members/${payload}`));
    yield put(Actions.deleteShopMemberSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.deleteShopMemberFailure(messages));
    }
  }
}

function* usePointShopMember({ payload }) {
  try {
    yield call(() => axiosMicro.patch(`/shop-members/use-points/${payload.id}`, payload.body));
    yield put(Actions.usePointShopMemberSuccess(payload));
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.usePointShopMemberFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.getAllShopMembersRequest, getAllShopMembers);
  yield takeLatest(Actions.createShopMemberRequest, createShopMember);
  yield takeLatest(Actions.updateShopMemberRequest, updateShopMember);
  yield takeLatest(Actions.deleteShopMemberRequest, deleteShopMember);
  yield takeLatest(Actions.usePointShopMemberRequest, usePointShopMember);
}
