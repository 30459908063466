const category = {
  'category.list': 'List category',
  'category.edit': 'Edit category',
  'category.delete': 'Delete category',
  'category.manage': 'Manage category',
  'category.create': 'Create category',
  'category.create.confirm': 'Confirm create category',
  'category.create.confirmSure': 'You will definitely create category?',
  'category.create.success': 'Create category successfully',
  'category.edit.confirm': 'Confirm edit category',
  'category.edit.confirmSure': 'You will definitely edit category?',
  'category.delete.confirm': 'Confirm delete category',
  'category.delete.confirmSure': 'You will definitely delete category?',
  'category.delete.success': 'Update category successfully',
  'category.update.success': 'Update category successfully',
};

const usLangCategory = {
  ...category,
};

export default usLangCategory;
