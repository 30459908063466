const workplace = {
  //
  'dashboard.workplace.radius': 'Bán kính',
  'dashboard.workplace.name': 'Tên địa điểm',
  'dashboard.workplace.place': 'Địa điểm',
  'dashboard.workplace.ipAddress': 'Địa chỉ IP',
  'dashboard.workplace.note': 'Ghi chú',
  'dashboard.workplace.manager': 'Quản lý địa điểm',
  'dashboard.workplace.list': 'Danh sách địa điểm',
  'dashboard.workplace.add': 'Thêm địa điểm',
  'dashboard.workplace.edit': 'Chỉnh sửa địa điểm',
  'dashboard.workplace.delete': 'Xóa địa điểm',
  'dashboard.workplace.confirm-add': 'Xác nhận thêm địa điểm',
  'dashboard.workplace.confirm-sure-add': 'Bạn chắc chắn sẽ thêm địa điểm này?',
  'dashboard.workplace.confirm-edit': 'Xác nhận nơi chỉnh sửa địa điểm',
  'dashboard.workplace.confirm-sure-edit': 'Bạn chắc chắn sẽ chỉnh sửa địa điểm này?',
  'dashboard.workplace.confirm-delete': 'Xác nhận xóa địa điểm',
  'workTracking.notWorking': 'Không làm',
  'dashboard.workplace.confirm-sure-delete': 'Bạn chắc chắn sẽ xóa địa điểm này?',
  'dashboard.schedule.edit': 'Cập nhật lịch làm việc',
  // form
  'dashboard.workplace.longitude': 'Kinh độ',
  'dashboard.workplace.latitude': 'Vĩ độ',
  'dashboard.workplace.name-require': 'Vui lòng nhập tên địa điểm',
  'dashboard.workplace.longitude-require': 'Vui lòng nhập kinh độ',
  'dashboard.workplace.latitude-require': 'Vui lòng nhập vĩ độ',
  'dashboard.workTracking.day': 'Ngày',
  'dashboard.workTracking.hours': 'Giờ',
  'dashboards.revenue': 'Doanh thu',

  // notification
  'dashboard.workplace.addSuccess': 'Thêm địa điểm thành công',
  'dashboard.workplace.addFailure': 'Thêm thất bại địa điểm',
  'dashboard.workplace.editSuccess': 'Chỉnh sửa địa điểm thành công',
  'dashboard.workplace.editFailure': 'Chỉnh sửa thất bại địa điểm',
  'dashboard.workplace.deleteSuccess': 'Xóa địa điểm thành công',
  'dashboard.workplace.deletedFailure': 'Xóa thất bại địa điểm',
  'dashboard.this-day': 'Hôm nay',
  'dashboard.this-week': 'Tuần',
  'dashboard.this-month': 'Tháng',
  'dashboard.this-year': 'Năm',
  'dashboard.revenue-number': 'Biểu đồ doanh số',
  'dashboard.revenue-export': 'Kho: Xuất bán - hoàn đến',
  'dashboard.revenue-grow': 'Biểu đồ tăng trưởng',
  'dashboard.warehouse.will-outInStock': 'Sắp hết hàng',
  'dashboard.warehouse.inventory': 'Tồn kho',
  'dashboard.revenue-grow.revenue': 'Doanh thu',
  'dashboard.revenue-grow.number': 'Số lượng',
  'dashboard.order-export': 'Số lượng đơn hàng',
  'dashboard.helmet-title': 'Trang chủ',
};

const schedule = {
  //
  'dashboard.schedule.code': 'ID',
  'dashboard.schedule.date': 'Ngày',
  'dashboard.schedule.time': 'Thời gian',
  'dashboard.schedule.note': 'Ghi chú',
  'dashboard.schedule.reason': 'Lý do',
  'dashboard.schedule.reason.require': 'Vui lòng nhập lý do',
  'dashboard.schedule.status': 'Trạng thái',
  'dashboard.schedule.businessList': 'Danh sách lịch làm việc',
  'dashboard.schedule.manager': 'Quản lý lịch làm việc',
  'dashboard.schedule.add': 'Thêm lịch làm việc',
  'dashboard.schedule.confirm-add': 'Xác nhận thêm lịch làm việc',
  'dashboard.schedule.confirm-sure-add': 'Bạn có chắc chắn để thêm lịch làm việc này?',
  'dashboard.schedule.confirm-edit': 'Xác nhận chỉnh sửa lịch làm việc',
  'dashboard.schedule.confirm-sure-edit': 'Bạn có chắc chắn để chỉnh sửa lịch làm việc này?',
  'dashboard.schedule.confirm-delete': 'Xác nhận xóa lịch làm việc',
  'dashboard.schedule.confirm-sure-delete': 'Bạn có chắc chắn xóa lịch làm việc này?',
  'dashboard.schedule.accept': 'Chấp nhận lịch làm việc',
  'dashboard.schedule.acceptFailure': 'Chấp nhận lịch làm việc thất bại',

  // form
  'dashboard.schedule.employee': 'Nhân viên',
  'dashboard.schedule.employee-require': 'Vui lòng chọn một nhân viên',
  'dashboard.schedule.place': 'Địa điểm',
  'dashboard.schedule.place-require': 'Vui lòng chọn một nơi để làm việc',
  'dashboard.schedule.date-start': 'Ngày bắt đầu',
  'dashboard.schedule.date-start-require': 'Vui lòng chọn một ngày bắt đầu',
  'dashboard.schedule.date-end': 'Ngày kết thúc',
  'dashboard.schedule.date-end-require': 'Vui lòng chọn một ngày kết thúc',
  'dashboard.schedule.time-from': 'Thời gian bắt đầu',
  'dashboard.schedule.time-from-require': 'Vui lòng chọn thời gian bắt đầu',
  'dashboard.schedule.time-to': 'Thời gian kết thúc',
  'dashboard.schedule.time-to-require': 'Vui lòng chọn thời gian kết thúc',
  // notification

  'dashboard.schedule.addSuccess': 'Thêm lịch làm việc thành công',
  'dashboard.schedule.addFailure': 'Thêm Lịch làm việc thất bại',
  'dashboard.schedule.editSuccess': 'Chỉnh sửa lịch làm việc thành công',
  'dashboard.schedule.editFailure': 'Chỉnh sửa lịch làm việc thất bại',
  'dashboard.schedule.deleteSuccess': 'Xóa lịch làm việc thành công',
  'dashboard.schedule.deletedFailure': 'Xóa thất bại lịch làm việc',

  // errors
  'dashboard.schedule.timeStart': 'Ngày bắt đầu không thể trước',
  'dashboard.schedule.timeEnd': 'Ngày kết thúc không thể trước ngày bắt đầu',
  'dashboard.schedule.timeEndLarger': 'Thời gian kết thúc không thể trước khi bắt đầu',
  'dashboard.schedule.timeExpiryLarger': 'Thời gian hết hạn không thể trước ngày kết thúc',
};

const vacation = {
  //
  'dashboard.vacation.list': 'Danh sách lịch nghỉ phép',
  'dashboard.vacation.list.employee': 'Thống kê nhân viên',
  'dashboard.vacation.manager': 'Quản lý lịch nghỉ phép',
  'dashboard.vacation.add': 'Thêm lịch nghỉ phép',
  'dashboard.vacation.edit': 'Chỉnh sửa lịch nghỉ phép',
  'dashboard.vacation.confirm-add': 'Xác nhận thêm lịch nghỉ phép',
  'dashboard.vacation.confirm-sure-add': 'Bạn có chắc chắn thêm lịch nghỉ phép này không?',
  'dashboard.vacation.confirm-edit': 'Xác nhận chỉnh sửa lịch nghỉ phép',
  'dashboard.vacation.confirm-sure-edit': 'Bạn có chắc chắn để chỉnh sửa lịch trình kỳ nghỉ phép này?',
  'dashboard.vacation.accept': 'Chấp nhận lịch nghỉ phép',
  'dashboard.vacation.acceptFailure': 'Chấp nhận lịch nghỉ phép thất bại',
  'dashboard.vacation.paidLeave': 'Nghỉ có lương',
  'dashboard.vacation.unPaidLeave': 'Nghỉ phép không lương',
  'dashboard.vacation.book': 'Xin nghỉ phép',
  'dashboard.vacation.user-backup': 'Người thay thế',
  'dashboard.vacation.name': 'Tên',
  // notification
  'dashboard.vacation.addSuccess': 'Thêm lịch nghỉ thành công',
  'dashboard.vacation.addFailure': 'Thêm Lịch trình kỳ nghỉ thất bại',
  'dashboard.vacation.editSuccess': 'Chỉnh sửa lịch nghỉ thành công',
  'dashboard.vacation.editFailure': 'Chỉnh sửa Lịch nghỉ Lỗi thất bại',
  'dashboard.vacation.deleteSuccess': 'Xóa lịch nghỉ thành công',
  'dashboard.vacation.deletedFailure': 'Xóa thất bại trong lịch trình nghỉ',
  'dashboard.vacation.pending': 'Chờ',
  'dashboard.vacation.noPaid': 'Nghỉ phép không lương',
  'dashboard.vacation.paid': 'Nghỉ phép có lương',
};

const workTracking = {
  //
  'dashboard.workTracking.name': 'Tên vận chuyển',
  'dashboard.workTracking.employee': 'Nhân viên',
  'dashboard.workTracking.place': 'Địa điểm',
  'dashboard.workTracking.time': 'Thời gian',
  'dashboard.workTracking.list': 'Nhật ký thời gian làm việc',
  'dashboard.workTracking.manager': 'Quản lý thời gian làm việc',
  'dashboard.workTracking.export': 'Xuất báo cáo',
};

const notification = {
  'dashboard.notification.list': 'Danh sách thông báo',
  'dashboard.notification.unRead': 'Thông báo chưa đọc',
  'dashboard.notification.order': 'Thông báo về đơn hàng',
  'dashboard.notification.notice': 'Thông báo',
  'dashboard.notification.time': 'Thời gian',
  'dashboard.notification.content': 'Nội dung thông báo',
  'dashboard.notification.system': 'Thông báo hệ thống',
};

const loginPage = {
  'auth.login.hetmet': 'Trang đăng nhập',
  'auth.login.title': 'Trang đăng nhập',
  'auth.login.sub-title-1': 'Đăng nhập bằng thông tin đăng nhập',
  'auth.login.sub-title-2': 'của bạn để truy cập vào hệ thống!',
  'auth.login.btn-login': 'Đăng nhập',
  'auth.login.err-msg': 'Email hoặc mật khẩu của bạn đã sai. Vui lòng kiểm tra lại.',
  'common.label.email': 'Email (Tên đăng nhập)',
  'common.label.password': 'Mật khẩu',
  'common.label.captcha': 'Mã bảo vệ',
  'auth.login.btn-login-google': 'Đăng nhập bằng Google',
  'auth.login.btn-login-staff': 'Đăng nhập với tư cách nhân viên',
  'common.label.Or': 'HOẶC',
  'auth.login.not-account': 'Chưa có tài khoản?',
  'auth.login.label-register': 'Đăng ký',
  'auth.login.label-forget-password': 'Quên mật khẩu?',
};

const RegisterPage = {
  'auth.register.hetmet': 'Đăng ký tài khoản',
  'auth.register.title': 'Đăng ký tài khoản',
  'auth.register.sub-title-1': 'Đăng nhập bằng thông tin đăng nhập',
  'auth.register.sub-title-2': 'của bạn để truy cập vào hệ thống!',
  'auth.register.btn-register': 'Đăng ký',
  'auth.register.err-msg': 'Thông tin đăng ký của bạn không đúng. Vui lòng kiểm tra lại.',
  'auth.register.has-account': 'Bạn đã có tài khoản?',
  'auth.register.label-login': 'Đăng nhập',
  'common.label.shopName': 'Tên cửa hàng',
  'common.label.phone': 'Số điện thoại (Zalo)',
  'common.label.email': 'Email',
  'common.label.address': 'Địa chỉ',
  'common.label.captcha': 'Mã bảo vệ',
  'common.label.branch': 'Ngành hàng',
};
const ForgetPasswordPage = {
  'auth.forgetPassword.title': 'QUÊN MẬT KHẨU',
  'auth.forgetPassword.change-password-title': 'THAY ĐỔI MẬT KHẨU',
  'auth.otp-confirm-title': 'OTP XÁC THỰC',
  'auth.forgetPassword.err-msg': 'Mật khẩu và mật khẩu xác nhận không trùng khớp. Vui lòng kiểm tra lại.',
  'auth.forgetPassword.form-pass-err-msg': 'Mật khẩu phải ít nhất 5 ký tự và có chữ và số. Vui lòng kiểm tra lại.',
  'auth.forgetPassword.email.notfound.err-msg': 'Email không chính xác. Vui lòng kiểm tra lại.',
  'auth.forgetPassword.otp.err-msg': 'OTP không chính xác. Vui lòng kiểm tra lại.',
  'auth.forgetPassword.placeholder': 'Mật khẩu mới',
  'auth.forgetPassword.placeholder.email': 'Nhập Email',
  'auth.forgetPassword.placeholder.otp': 'Mã OTP',
  'auth.forgetPassword.placeholder.otp-required': 'Nhập mã OTP',
  'auth.forgetPassword.placeholder.sent-otp-later': 'Bạn chưa nhận được mã OTP? Gửi lại sau',
  'auth.forgetPassword.placeholder-required': 'Nhập lại mật khẩu mới',
  'auth.forgetPassword.notify.success.title': 'THÀNH CÔNG',
  'auth.forgetPassword.notify.success': 'Mật khẩu thay đổi thành công',
  'auth.forgetPassword.notify.check-email': 'Kiểm tra địa chỉ Email',
  'auth.forgetPassword.notify.check-email-to-change': 'để lấy mã thay đổi',
  'auth.forgetPassword.otp.is.value-in-time': 'Mã OTP có giá trị trong',
  'auth.forgetPassword.notify.check-email-zalo': 'Bạn vui lòng kiểm tra Zalo/Email để nhận mã OTP kích hoạt tài khoản',
  'auth.forgetPassword.email-required': 'Nhập địa chỉ email đăng ký với Satek',

};

const chart = {
  'dashboard.chart.products-best-selling': 'Danh sách sản phẩm bán chạy',
  'dashboard.chart.revenue': 'Doanh thu',
  'dashboard.chart.revenue.today': 'Doanh thu hôm nay',
  'dashboard.chart.revenue.today.order.count': 'Đơn đã bán',
  'dashboard.chart.revenue.today.order.number': 'Số lượng sản phẩm đã bán',
  'dashboard.chart.revenue.today.order.total': 'Tổng tiền',
  'dashboard.chart.revenue.today.order.decrease': 'Tổng giảm',
  'dashboard.chart.revenue.today.return.count': 'Đơn đã trả',
  'dashboard.chart.revenue.today.return.number': 'Số lượng sản phẩm trả',
  'dashboard.chart.revenue.today.return.money': 'Tổng tiền hoàn',
  'dashboard.chart.products-expiry': 'Sản phẩm sắp hết hạn',
  'dashboard.chart.products-almost-outInStock': 'Sản phẩm sắp hết hàng',
  'dashboard.redirect.product-lookup': 'Chuyển đến trang tồn kho',
  // field

  'dashboard.product.name': 'Tên sản phẩm',
  'dashboard.product.number': 'Số lượng',
  'dashboard.product.expiry': 'Hạn sử dụng',
  'dashboard.error.nothingFind': 'Không tìm thấy thông tin',

};

const vnLangDashboard = {
  ...workplace,
  ...schedule,
  ...vacation,
  ...workTracking,
  ...notification,
  ...loginPage,
  ...chart,
  ...RegisterPage,
  ...ForgetPasswordPage,
};

export default vnLangDashboard;
