const staff = {
  'staff.list': 'List staff',
  'staff.delete': 'Delete staff',
  'staff.manage': 'Manage staff',
  'staff.create': 'Create staff',
  'staff.create.confirm': 'Confirm create staff',
  'staff.create.confirmSure': 'You will definitely create staff?',
  'staff.create.success': 'Create staff successfully',
  'staff.edit': 'Edit staff',
  'staff.edit.confirm': 'Confirm edit staff',
  'staff.edit.confirmSure': 'You will definitely edit staff?',
  'staff.delete.confirm': 'Confirm delete staff',
  'staff.delete.confirmSure': 'You will definitely delete staff?',
  'staff.update.success': 'Update staff successfully',
  'staff.delete.success': 'Delete staff successfully',
  'staff.work': 'Type staff',
  'staff.parttime': 'Part time',
  'staff.fulltime': 'Full time',
  'staff.currentWorkTime': 'Current work time',
  'staff.resetSuccess': 'Reset password staff successfully',
  'staff.cumulative': 'Cumulative hours',
  'staff.wage': 'Wage',
  'staff.settled': 'Settled',
  'staff.disabled.confirm': 'Confirm disabled staff',
  'staff.disabled.confirmSure': 'ou will definitely disabled this staff',
  'staff.active.confirm': 'Confirm enable staff',
  'staff.active.confirmSure': 'You will definitely enable this staff',
  'staff.basic_salary': 'Basic salary',
  'staff.basic_salary.hours': 'Salary by hours',
  'staff.basic_salary.month': 'Salary by month',
  'staff.work_per_day': 'Work per day',
  'staff.work_per_month': 'Work per month',
  'staff.ot': 'Over time',
  'staff.allowance': 'Allowance',
  'staff.break': 'Number of lunch breaks',
  'staff.tax': 'Tax',
  'staff.social_security': 'Social security',
  'staff.health_insurance': 'Health insurance',
  'staff.salary.previous': 'Previous period excess money',
  'staff.enable': 'Active',
  'staff.disable': 'Disabled',
  'staff.block': 'Block account',
  'staff.unblock': 'Unblock account',
  'staff.pay': 'Pay',
  'staff.pay.all': 'Pay all',
  'staff.pay.once': 'Pay once',
  'staff.remunerate': 'Remunerate',
  'staff.punish': 'Punish',
  'staff.salary.history': 'Wage payment history',
  'staff.salary.pay': 'Pay staff salary',
  'staff.salary.change': 'Change salary',
  'staff.salary.enter': 'Enter the amount to pay the staff',
  'staff.salary.pre_period': 'Pre-period',
  'staff.salary.prepay': 'Prepay',
  'staff.salary.history-wage': 'Payment history',
  'staff.salary.date-wage': 'Date payment',
  'staff.salary.money-wage': 'Wage',
  'staff.salary.total-time': 'Total time',
  'staff.salary.work-time': 'work time',
  'staff.salary.bonus': 'Bonus',
  'staff.salary.deduction': 'Deduction',
  'staff.salary.advance': 'Salary advance',
  'staff.salary.excess-lack': 'Excess/lack',
  'staff.salary.total-money': 'Total money',
  'staff.salary.employee.payments': 'Employee payment amount',
  'staff.salary.paid': 'Paid',
  'staff.salary.remaining.amount': 'Remaining amount',
  'staff.salary.dont-wage-pay-history': 'The employee has no wage payment history',
  'staff.salary.total-bonus': 'Total bonus',
  'staff.salary.time-job': 'time job',
  'staff.salary.date-job': 'Work day',
  'staff.salary.accumulation-time': 'Accumulated time',
  'staff.cumulative-date': 'Cumulative days',
  'staff.salary.invalid-money': 'The amount paid is greater than the actual amount received',
  'staff.salary.invalid-date': 'Invalid date',
  'staff.salary.confirm-pay': 'Payment confirmation',
  'staff.salary.confirm-pay-part': 'Confirmation of partial payment',
  'staff.salary.confirm-pay-sure': 'Are you sure to pay?',
  'staff.salary.no-work-time': 'No work time',
  'staff.resetPassword.success': 'Reset password staff successfully',
};

const usLangStaff = {
  ...staff,
};

export default usLangStaff;
