const kitchen = {
  'sidebar.kitchen-page': 'Bếp',
  'kitchen.fnb.tab-kitchen': 'Bếp',
};

const vnLangKitchen = {
  ...kitchen,
};

export default vnLangKitchen;
