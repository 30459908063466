const vnLangReport = {
  'report.col.code': 'Mã HD',
  'report.col.name': 'Món ăn',
  'report.order.breadcrumb.title': 'Danh sách các món bị hủy',
  'report.staff.breadcrumb.title': 'Danh sách nhân viên hay hủy món',
  'report.col.date': 'Ngày',
  'report.col.total.name': 'Số lượng',
  'report.col.total-date.name': 'Tổng số lượng',
  'report.col.name.label': 'Nhân viên',
  'report.col.date.label': 'Thời gian',
  'report.col.time.label': 'Số lần hủy món',
  'report.col.total.label': 'Tổng số lần hủy món',

  'report.revenue.code': 'Mã đơn',
  'report.revenue.customer': 'Khách hàng',
  'report.revenue.money': 'Đơn giá',
  'report.revenue.discount': 'Giảm giá',
  'report.revenue.total': 'Thành tiền',
  'report.revenue.voucher': 'Mã khuyến mãi',
  'report.revenue.status': 'Trạng thái',
  'report.revenue.time': 'Thời gian',
  'report.reveneu.number': 'Số thứ tự',
  'report.reveneu.title': 'Tiêu đề',
  'report.reveneu.content': 'Nội dung',
  'report.reveneu.detail-request': 'Xem thêm chi tiết',
  'report.reveneu.detail-hidden': 'Ẩn',
  'report.discount.product.voucher': 'Voucher sản phẩm',

};

export default vnLangReport;
