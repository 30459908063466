const usLangDiscount = {
  'discount.helmet': 'List of discounts',
  'discount.breadcrumb.title': 'List of discounts',
  'discount.breadcrumb.parent': 'Sell',
  'discount.modal.create.title': 'Add discount',
  'discount.modal.create.confirm.title': 'Confirm new discounts',
  'discount.modal.create.confirm.description': 'You definitely want to add this discount?',
  'discount.modal.detail.title': 'the detail information of discount',
  'discount.modal.update.title': 'Update discount information',
  'discount.modal.update.confirm.title': 'Confirmation of discount information updates',
  'discount.modal.update.confirm.description': 'You definitely want to update this discount information?',
  'discount.modal.delete.title': 'discount deletion confirmation',
  'discount.modal.delete.description': 'You definitely want to delete this discount?',
  'discount.modal.reset-password.title': 'Reset Password',
  'discount.noti.create.success': 'Add successful discounts!',
  'discount.noti.create.failure': 'Add unsuccessful discounts!Please try again later.',
  'discount.noti.update.success': 'Update the discount successfully!',
  'discount.noti.update.failure': 'Update the discount unsuccessful!Please try again later.',
  'discount.noti.delete.success': 'Delete discounts successfully!',
  'discount.noti.delete.failure': 'Delete discounts failed!Please try again later.',
  'discount.noti.reset-password.success': 'Retite a successful discount password!',
  'discount.noti.reset-password.failure': 'Reset the discount password failed!Please try again later.',
  'discount.btn-create': 'Create',
  'discount.error-min-length': 'Minimum $x character!',
  'discount.error-max-length': 'Maximum $x character!',
  'discount.code.col': 'discount code',
  'discount.code.label': 'discount code',
  'discount.code.placeholder': 'Enter the discount code',
  'discount.barcode.col': 'Barcode',
  'discount.barcode.label': 'Barcode',
  'discount.barcode.placeholder': 'Enter the barcode',
  'discount.categories.col': 'discount line',
  'discount.categories.label': 'discount line',
  'discount.categories.placeholder': 'Choose a discount line',
  'discount.categories.error.required': 'Please choose the discount line!',
  'discount.name.col': "discount's name",
  'discount.name.label': "discount's name",
  'discount.name.placeholder': 'Enter the name of the discount',
  'discount.name.error.required': 'Please enter the discount name!',
  'discount.desc.col': 'Describe',
  'discount.desc.label': 'discount Description',
  'discount.desc.placeholder': 'Enter the discount description',
  'discount.unit.col': 'Unit',
  'discount.unit.label': 'Basic unit',
  'discount.unit.placeholder': 'Enter the basic unit',
  'discount.unit.error-required': 'Please enter the basic unit!',
  'discount.price.col': 'Price',
  'discount.price.label': 'Price (VND)',
  'discount.price.placeholder': 'Enter the selling price',
  'discount.price.error-required': 'Please enter the selling price!',
  'discount.price.invalid': 'Enter the price greater than 0!',
  'discount.units.name.label': 'Unit name',
  'discount.units.name.placeholder': 'Enter the name of the unit',
  'discount.units.name.error-required': 'Please enter the name of the unit!',
  'discount.units.number.label': 'Exchange value',
  'discount.units.number.placeholder': 'Enter the conversion value',
  'discount.units.number.error-required': 'Please enter the conversion value!',
  'discount.units.number.error-invalid': 'The conversion value must be greater than 0!',
  'discount.units.price.label': 'Price (VND)',
  'discount.units.price.placeholder': 'Enter the selling price',
  'discount.units.price.error-required': 'Please enter the selling price!',
  'discount.units.code.label': 'discount code',
  'discount.units.code.placeholder': 'Enter the discount code',
  'discount.units.barcode.label': 'Barcode',
  'discount.units.barcode.placeholder': 'Enter the barcode',
  'discount.image.col': 'Image',
  'discount.image.label': 'Upload',
  'discount.image.label-2': 'discount image',
  'discount.image.error-required': 'Please upload the discount photo!',
  'discount.btn-add-units-items': 'Add unit',
  'discount.type.label': 'Method',
  'discount.number.label': 'Number or percent discount',
  'discount.quantity.label': 'Limit to apply',
  'discount.duration.label': 'Time duration (s)',
  'discount.maximum.label': 'Maximum reduction ( if % is applied )',
  'discount.condition.label': 'Conditions applicable (total >)',
  'discount.type.required': 'Please choose the type of discount',
  'discount.start.label': 'Start date',
  'discount.expiry.label': 'Expiry date',
  'discount.end.label': 'End date',
  'discount.start.required': 'Please enter start date',
  'discount.end.required': 'Please enter end date',
  'discount.start.not-valid': 'Start date must be valid',
  'discount.end.not-valid': 'End date must be valid',
  'discount.money': 'Money',
  'discount.percent': 'Percent',
  'discount.gift': 'Gift',
  'discount.gift.label': 'Gift name',
  'discount.voucher.value-gift': 'Choose gift',
  'discount.type-low-items-gift': 'Lowest',
  'discount.type-high-items-gift': 'Highest',
  'discount.type-option-items-gift': 'Custom',
  'discount.voucher.input-product': 'Choose product',
  'discount.voucher.input-number': 'Input number',
  'discount.voucher.input-type-gift': 'Choose gift type',
  'discount.voucher.order': 'Discount order',
  'discount.voucher.product': 'Discount product',
  'discount.remain.label': 'Remaining quantity',
};

export default usLangDiscount;
