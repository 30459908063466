const usLangSaler = {
  'saler.helmet': 'List of sales staff',
  'saler.breadcrumb.title': 'List of sales staff',
  'saler.breadcrumb.parent': 'user management',
  'saler.modal.create.title': 'Add sales staff',
  'saler.modal.create.confirm.title': 'Confirmation of new sales staff',
  'saler.modal.create.confirm.description': 'You definitely want to add this salesman?',
  'saler.modal.detail.title': 'Details of sales staff',
  'saler.modal.update.title': 'Update sales staff information',
  'saler.modal.update.confirm.title': 'Confirm the update of business staff information',
  'saler.modal.update.confirm.description': 'You definitely want to update this salesman information?',
  'saler.modal.delete.title': 'Confirmation of deletion of sales staff',
  'saler.modal.delete.description': 'You definitely want to delete this salesman?',
  'saler.modal.reset-password.title': 'Reset Password',
  'saler.noti.create.success': 'Add successful sales staff!',
  'saler.noti.create.failure': 'Adding business staff unsuccessful!Please try again later.',
  'saler.noti.update.success': 'Successful update of sales staff!',
  'saler.noti.update.failure': 'Update business staff unsuccessful!Please try again later.',
  'saler.noti.delete.success': 'Delete Sales Staff successfully!',
  'saler.noti.delete.failure': 'Delete business staff unsuccessful!Please try again later.',
  'saler.noti.reset-password.success': 'Retite the password of the sales staff successfully!',
  'saler.noti.reset-password.failure': 'Return to the password of the business staff unsuccessful!Please try again later.',
  'saler.btn-create': 'Create',
  'saler.name.col': 'Full name',
  'saler.name.label': 'Full name',
  'saler.name.placeholder': 'Enter the full name',
  'saler.name.error-required': 'Please enter the full name!',
  'saler.name.error-min-length': 'Minimum $x character!',
  'saler.name.error-max-length': 'Maximum $x character!',
  'saler.phone.col': 'Phone number',
  'saler.phone.label': 'Phone number',
  'saler.phone.placeholder': 'Enter your phone number',
  'saler.phone.error-required': 'Please enter the phone number!',
  'saler.phone.error-invalid': 'Invalid phone number!',
  'saler.email.col': 'Gmail',
  'saler.email.label': 'Gmail',
  'saler.email.placeholder': 'Enter Gmail',
  'saler.email.error-required': 'Please enter Gmail!',
  'saler.email.error-invalid': 'Gmail is not valid!',
  'saler.password.label': 'Password',
  'saler.password.placeholder': 'Enter password',
  'saler.password.error-required': 'Please enter a password!',
};

export default usLangSaler;
