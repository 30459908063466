const usLangReport = {
  'report.col.code': 'Mã HD',
  'report.col.name': 'Món ăn',
  'report.order.breadcrumb.title': 'Danh sách các món bị hủy',
  'report.staff.breadcrumb.title': 'Danh sách nhân viên hay hủy món',
  'report.col.date': 'Ngày',
  'report.col.total.name': 'Số lượng',
  'report.col.total-date.name': 'Tổng số lượng',
  'report.col.name.label': 'Nhân viên',
  'report.col.date.label': 'Thời gian',
  'report.col.time.label': 'Số lần hủy món',
  'report.col.total.label': 'Tổng số lần hủy món',

  'report.revenue.code': 'Code',
  'report.revenue.customer': 'Customer',
  'report.revenue.money': 'money',
  'report.revenue.discount': 'Discount',
  'report.revenue.total': 'Total',
  'report.revenue.voucher': 'Voucher',
  'report.revenue.status': 'Status',
  'report.revenue.time': 'Time',
  'report.reveneu.number': 'No',
  'report.reveneu.title': 'Title',
  'report.reveneu.content': 'Content',
  'report.reveneu.detail-request': 'See more details',
  'report.reveneu.detail-hidden': 'Hide',
  'report.discount.product.voucher': 'Voucher product',
};

export default usLangReport;
