const packageTrans = {
  'package.create': 'Thêm gói dịch vụ',
  'package.list': 'Danh sách gói dịch vụ',
  'package.edit': 'Cập nhật gói dịch vụ',
  'package.delete': 'Xóa gói dịch vụ',
  'package.manage': 'Quản lý gói dịch vụ',
  'package.create.confirm': 'Xác nhận tạo gói dịch vụ',
  'package.create.confirmSure': 'Bạn chắc chắn tạo gói dịch vụ?',
  'package.edit.confirm': 'Xác nhận cập nhật gói dịch vụ',
  'package.edit.confirmSure': 'Bạn chắc chắn cập nhật gói dịch vụ?',
  'package.create.success': 'Tạo gói dịch vụ thành công',
  'package.update.success': 'Cập nhật gói dịch vụ thành công',
  'package.delete.confirm': 'Xác nhận xóa gói dịch vụ',
  'package.delete.confirmSure': 'Bạn chắc chắn xóa gói dịch vụ?',
  'package.delete.success': 'Xóa gói dịch vụ thành công',

  'package.name': 'Tên gói dịch vụ',
  'package.money': 'Giá',
  'package.month': 'Số tháng sử dụng',
  'package.items': 'Các tính năng',
};

const featureTrans = {
  'feature.create': 'Thêm chức năng',
  'feature.list': 'Danh sách chức năng',
  'feature.edit': 'Cập nhật chức năng',
  'feature.delete': 'Xóa chức năng',
  'feature.manage': 'Quản lý chức năng',
  'feature.create.confirm': 'Xác nhận tạo chức năng',
  'feature.create.confirmSure': 'Bạn chắc chắn tạo chức năng?',
  'feature.edit.confirm': 'Xác nhận cập nhật chức năng',
  'feature.edit.confirmSure': 'Bạn chắc chắn cập nhật chức năng?',
  'feature.create.success': 'Tạo chức năng thành công',
  'feature.update.success': 'Cập nhật chức năng thành công',
  'feature.delete.confirm': 'Xác nhận xóa chức năng',
  'feature.delete.confirmSure': 'Bạn chắc chắn xóa chức năng?',
  'feature.delete.success': 'Xóa chức năng thành công',

  'feature.name': 'Tên chức năng',
  'feature.price': 'Giá bán',
  'feature.origin': 'Giá gốc',
  'feature.image': 'Hình ảnh',
  'feature.description': 'Mô tả',
};

const paymentTrans = {
  'payment.create': 'Thêm hình thức hành toán',
  'payment.list': 'Danh sách hình thức hành toán',
  'payment.edit': 'Cập nhật hình thức hành toán',
  'payment.delete': 'Xóa hình thức hành toán',
  'payment.manage': 'Quản lý hình thức hành toán',
  'payment.create.confirm': 'Xác nhận tạo hình thức hành toán',
  'payment.create.confirmSure': 'Bạn chắc chắn tạo hình thức hành toán?',
  'payment.edit.confirm': 'Xác nhận cập nhật hình thức hành toán',
  'payment.edit.confirmSure': 'Bạn chắc chắn cập nhật hình thức hành toán?',
  'payment.create.success': 'Tạo hình thức hành toán thành công',
  'payment.update.success': 'Cập nhật hình thức hành toán thành công',
  'payment.delete.confirm': 'Xác nhận xóa hình thức hành toán',
  'payment.delete.confirmSure': 'Bạn chắc chắn xóa hình thức hành toán?',
  'payment.delete.success': 'Xóa hình thức hành toán thành công',

  'payment.type': 'Hình thức',
  'payment.type.e-wallet': 'E-wallet',
  'payment.type.banking': 'Ngân hàng',
  'payment.type.required': 'Vui lòng chọn hình thức',
  'payment.title': 'Tên',
  'payment.title.required': 'Vui lòng nhập tên',
  'payment.image': 'Hình ảnh',
  'payment.image.required': 'Vui lòng chọn hình ảnh',
  'payment.bank': 'Ngân hàng',
  'payment.bank.required': 'Vui lòng nhập tên ngân hàng',
  'payment.account': 'Số tài khoản',
  'payment.account.required': 'Vui lòng nhập số tài khoản',
  'payment.owner': 'Chủ tài khoản',
  'payment.owner.required': 'Vui lòng nhập tên chủ tài khoản',
  'payment.qrcode': 'Mã qr thanh toán',
  'payment.url.incorrect': 'Đường dẫn không đúng định dạng',
};

const transactionTrans = {
  'transaction.create': 'Thêm giao dịch',
  'transaction.list': 'Danh sách giao dịch',
  'transaction.edit': 'Cập nhật giao dịch',
  'transaction.delete': 'Xóa giao dịch',
  'transaction.manage': 'Quản lý giao dịch',
  'transaction.create.confirm': 'Xác nhận tạo giao dịch',
  'transaction.create.confirmSure': 'Bạn chắc chắn tạo giao dịch?',
  'transaction.edit.confirm': 'Xác nhận cập nhật giao dịch',
  'transaction.edit.confirmSure': 'Bạn chắc chắn cập nhật giao dịch?',
  'transaction.create.success': 'Tạo giao dịch thành công',
  'transaction.update.success': 'Cập nhật giao dịch thành công',
  'transaction.delete.confirm': 'Xác nhận xóa giao dịch',
  'transaction.delete.confirmSure': 'Bạn chắc chắn xóa giao dịch?',
  'transaction.delete.success': 'Xóa giao dịch thành công',

  'transaction.name': 'Tên gói',
  'transaction.shop': 'Khách hàng',
  'transaction.money': 'Số tiền giao dịch',
  'transaction.note': 'Ghi chú',
  'transaction.status': 'Trạng thái',
  'transaction.status.required': 'Vui lòng chọn trạng thái ',
  'transaction.provider': 'Thanh toán bằng',
};

const vnLangConfig = {
  ...packageTrans,
  ...featureTrans,
  ...paymentTrans,
  ...transactionTrans,
};

export default vnLangConfig;
