/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';

import { axiosMicro } from '~/services';
import * as Actions from './actions';

function* signUp({ payload }) {
  try {
    yield call(() => axios.post(`${process.env.REACT_APP_DEV_MICRO_APP}/shops/register`, payload));
    yield put(Actions.signUpSuccess());
  } catch (error) {
    const messages = error?.response?.data;
    yield put(Actions.signUpFailure(messages));
  }
}

function* signIn({ payload }) {
  try {
    const response = yield call(() => axios.post(
      `${process.env.REACT_APP_DEV_MICRO_APP}/clients/web/login`,
      payload,
    ));
    const {
      token_type, access_token, refresh_token, expires_in,
    } = response.data;
    localStorage.setItem('accessToken', `${token_type} ${access_token}`);
    localStorage.setItem('refresh_token', refresh_token);
    localStorage.setItem('expires_in', expires_in);
    localStorage.setItem('is_login', true);
    yield put(Actions.signInSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.signInFailure(messages));
    }
  }
}

function* changePassword({ payload }) {
  try {
    const { id, currentPassword, newPassword } = payload;
    const response = yield call(() => axiosMicro.patch(`/users/${id}/password`, {
      current_password: currentPassword,
      new_password: newPassword,
    }));
    yield put(Actions.changePasswordSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.changePasswordFailure(messages));
    }
  }
}

function* requireChangePassword({ payload }) {
  try {
    const { id, currentPassword, newPassword } = payload;
    const response = yield call(() => axiosMicro.patch(`/users/${id}/password`, {
      current_password: currentPassword,
      new_password: newPassword,
    }));
    yield put(Actions.requireChangePasswordSuccess());
  } catch (error) {
    if (error?.response?.data) {
      const messages = error.response.data;
      yield put(Actions.requireChangePasswordFailure(messages));
    }
  }
}
function* register({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/shops/register', payload));
    yield put(Actions.registerSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.registerFailure(messages));
    }
  }
}

function* signInWithGoogle({ payload }) {
  try {
    const response = yield call(() => axios.post(`${process.env.REACT_APP_DEV_MICRO_APP}/auth/google`, payload));
    const {
      access_token,
    } = response.data.meta.custom;
    localStorage.setItem('accessToken', `Bearer ${access_token}`);
    localStorage.setItem('is_login', true);
    yield put(Actions.signInWithGoogleSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.signInWithGoogleFailure(messages));
    }
  }
}

function* createOTP({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/otps', payload));
    yield put(Actions.createOTPRequestSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.createOTPRequestFailure(messages));
    }
  }
}

function* checkOtp({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/otps/check', payload));
    if (response?.data) {
      yield put(Actions.checkOtpRequestSuccess(response.data));
    } else {
      yield put(Actions.checkOtpRequestFailure('OTP không đúng'));
    }
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.checkOtpRequestFailure(messages));
    }
  }
}

function* resetPasswordUser({ payload }) {
  try {
    const response = yield call(() => axiosMicro.post('/password/reset', payload));
    yield put(Actions.resetUserPasswordRequestSuccess(response.data));
  } catch (e) {
    if (e?.response?.data) {
      const messages = e.response.data;
      yield put(Actions.resetUserPasswordRequestFailure(messages));
    }
  }
}

// eslint-disable-next-line func-names
export default function* () {
  yield takeLatest(Actions.signInRequest, signIn);
  yield takeLatest(Actions.signUpRequest, signUp);
  yield takeLatest(Actions.changePasswordRequest, changePassword);
  yield takeLatest(Actions.requireChangePasswordRequest, requireChangePassword);
  yield takeLatest(Actions.registerRequest, register);
  yield takeLatest(Actions.signInWithGoogleRequest, signInWithGoogle);
  yield takeLatest(Actions.createOTPRequest, createOTP);
  yield takeLatest(Actions.checkOtpRequest, checkOtp);
  yield takeLatest(Actions.resetUserPasswordRequest, resetPasswordUser);
}
