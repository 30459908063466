const vnLangSupplier = {
  'supplier.helmet': 'Danh sách nhà cung cấp',
  'supplier.breadcrumb.title': 'Danh sách nhà cung cấp',
  'supplier.breadcrumb.parent': 'Bán hàng',
  'supplier.modal.create.title': 'Thêm nhà cung cấp',
  'supplier.modal.create.confirm.title': 'Xác nhận thêm nhà cung cấp mới',
  'supplier.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm nhà cung cấp này?',
  'supplier.modal.detail.title': 'Thông tin chi tiết nhà cung cấp',
  'supplier.modal.update.title': 'Cập nhật thông tin nhà cung cấp',
  'supplier.modal.update.confirm.title': 'Xác nhận cập nhật thông tin nhà cung cấp',
  'supplier.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin nhà cung cấp này?',
  'supplier.modal.delete.title': 'Xác nhận xoá nhà cung cấp',
  'supplier.modal.delete.description': 'Bạn chắc chắn muốn xoá nhà cung cấp này?',
  'supplier.modal.reset-password.title': 'Đặt lại mật khẩu',
  'supplier.noti.create.success': 'Thêm nhà cung cấp thành công!',
  'supplier.noti.create.failure': 'Thêm nhà cung cấp không thành công! Vui lòng thử lại sau.',
  'supplier.noti.update.success': 'Cập nhật nhà cung cấp thành công!',
  'supplier.noti.update.failure': 'Cập nhật nhà cung cấp không thành công! Vui lòng thử lại sau.',
  'supplier.noti.delete.success': 'Xoá nhà cung cấp thành công!',
  'supplier.noti.delete.failure': 'Xoá nhà cung cấp không thành công! Vui lòng thử lại sau.',
  'supplier.noti.reset-password.success': 'Đặt lại mật khẩu nhà cung cấp thành công!',
  'supplier.noti.reset-password.failure': 'Đặt lại mật khẩu nhà cung cấp không thành công! Vui lòng thử lại sau.',
  'supplier.btn-create': 'Thêm',
  'supplier.error-min-length': 'Tối thiểu $x ký tự!',
  'supplier.error-max-length': 'Tối đa $x ký tự!',
  'supplier.code.col': 'Mã nhà cung cấp',
  'supplier.code.label': 'Mã nhà cung cấp',
  'supplier.code.placeholder': 'Nhập mã nhà cung cấp',
  'supplier.barcode.col': 'Mã vạch',
  'supplier.barcode.label': 'Mã vạch',
  'supplier.barcode.placeholder': 'Nhập mã vạch',
  'supplier.categories.col': 'Dòng nhà cung cấp',
  'supplier.categories.label': 'Dòng nhà cung cấp',
  'supplier.categories.placeholder': 'Chọn dòng nhà cung cấp',
  'supplier.categories.error.required': 'Vui lòng chọn dòng nhà cung cấp!',
  'supplier.name.col': 'Tên nhà cung cấp',
  'supplier.name.label': 'Tên nhà cung cấp',
  'supplier.name.placeholder': 'Nhập tên nhà cung cấp',
  'supplier.name.error.required': 'Vui lòng nhập tên nhà cung cấp!',
  'supplier.desc.col': 'Mô tả',
  'supplier.desc.label': 'Mô tả nhà cung cấp',
  'supplier.desc.placeholder': 'Nhập mô tả nhà cung cấp',
  'supplier.unit.col': 'Đơn vị',
  'supplier.unit.label': 'Đơn vị tính cơ bản',
  'supplier.unit.placeholder': 'Nhập đơn vị tính cơ bản',
  'supplier.unit.error-required': 'Vui lòng nhập đơn vị tính cơ bản!',
  'supplier.price.col': 'Giá bán',
  'supplier.price.label': 'Giá bán (vnđ)',
  'supplier.price.placeholder': 'Nhập giá bán',
  'supplier.price.error-required': 'Vui lòng nhập giá bán!',
  'supplier.price.invalid': 'Nhập giá lớn hơn 0!',
  'supplier.units.name.label': 'Tên đơn vị',
  'supplier.units.name.placeholder': 'Nhập tên đơn vị',
  'supplier.units.name.error-required': 'Vui lòng nhập tên đơn vị!',
  'supplier.units.number.label': 'Giá trị quy đổi',
  'supplier.units.number.placeholder': 'Nhập giá trị quy đổi',
  'supplier.units.number.error-required': 'Vui lòng nhập giá trị quy đổi!',
  'supplier.units.number.error-invalid': 'Giá trị quy đổi phải lớn hơn 0!',
  'supplier.units.price.label': 'Giá bán (vnđ)',
  'supplier.units.price.placeholder': 'Nhập giá bán',
  'supplier.units.price.error-required': 'Vui lòng nhập giá bán!',
  'supplier.units.code.label': 'Mã nhà cung cấp',
  'supplier.units.code.placeholder': 'Nhập mã nhà cung cấp',
  'supplier.units.barcode.label': 'Mã vạch',
  'supplier.units.barcode.placeholder': 'Nhập mã vạch',
  'supplier.image.col': 'Hình ảnh',
  'supplier.image.label': 'Tải lên',
  'supplier.image.label-2': 'Hình ảnh nhà cung cấp',
  'supplier.image.error-required': 'Vui lòng tải lên ảnh nhà cung cấp!',
  'supplier.btn-add-units-items': 'Thêm đơn vị tính',
  'supplier.email.validate': 'Email không đúng định dạng',
  'supplier.phone.label': 'Số điện thoại nhà cung cấp',
  'supplier.phone.placeholder': 'Nhập số điện thoại nhà cung cấp',
  'supplier.email.label': 'Email nhà cung cấp',
  'supplier.email.placeholder': 'Nhập email nhà cung cấp',
  'supplier.address.label': 'Địa chỉ nhà cung cấp',
  'supplier.address.placeholder': 'Nhập địa chỉ nhà cung cấp',
};

export default vnLangSupplier;
