const shop = {
  'shop.name': 'Name shop',
  'shop.create': 'Create shop',
  'shop.list': 'List shop',
  'shop.edit': 'Edit shop',
  'shop.trial': 'Trial',
  'shop.trial.7': '7 day',
  'shop.trial.15': '15 day',
  'shop.delete': 'Delete shop',
  'shop.manage': 'Manage shop',
  'shop.create.confirm': 'Confirm create shop',
  'shop.create.confirmSure': 'You will definitely create shop?',
  'shop.edit.confirm': 'Confirm edit shop',
  'shop.edit.confirmSure': 'You will definitely edit shop?',
  'shop.create.success': 'Create shop successfully',
  'shop.update.success': 'Update shop successfully',
  'shop.resetPassword.success': 'Reset password shop successfully',
  'shop.confirm.resetPassword': 'Confirm reset password shop?',
  'shop.detail': 'Detail shop',
  'shop.username.required': 'Please enter the username',
  'shop.shop_name.required': 'Please enter the store name',
  'shop.name_customer.required': 'Please enter the customer name',
  'shop.phone.required': 'Please enter the store phone number',
  'shop.password.required': 'Please enter a password',
  'shop.type.label': 'Type of service',
  'shop.type.value.pharmacy': 'Drugstore',
  'shop.type.value.table': 'Book',
  'shop.type.value.order': 'Other',
  'shop.quantity.label': 'There is a manager of the number of warehouses',
  'shop.auto.label': 'Automatically create departure cards',

  'shop.active': 'Active',
  'shop.new': 'New',
  'shop.expried': 'Expired',

  'shop.renewal.create': 'Package renewal',
  'shop.renewal.confirm': 'Confirm the package renewal',
  'shop.renewal.confirmSure': 'You definitely renew the package',
  'shop.renewal.success': 'Successful renewal',

};

const config = {
  'shop-config.save': 'Save configuration',
  'shop-config.save.success': 'Save successfully configuration',
  'shop-config.save.confirm': 'Confirm configuration',
  'shop-config.save.confirmSure': 'Are you definitely configuration?',
  'shop-config.hetmet': 'Store configuration',
  'shop-config.breadcrumb-title': 'Store configuration',
  'shop-config.breadcrumb-parent': 'Store configuration',
  'shop-config.bank': 'Transfer configuration',
  'shop-config.bank.name': 'Account name',
  'shop-config.bank.name.error-required': 'Account name is mandatory',
  'shop-config.bank.name.placeholder': 'Please enter your account name',
  'shop-config.bank.account': 'Account number',
  'shop-config.bank.account.error-required': 'Account number is mandatory',
  'shop-config.bank.account.placeholder': 'Please enter the account number',
  'shop-config.bank.nameBank': 'Bank name',
  'shop-config.bank.nameBank.error-required': 'Bank name is mandatory',
  'shop-config.bank.nameBank.placeholder': 'Please enter the bank name',
  'shop-config.bill': 'Invoice configuration',
  'shop-config.bill.nameShop': 'Name of the store',
  'shop-config.bill.nameShop.placeholder': 'Enter the name of the store',
  'shop-config.bill.phoneShop': 'Store phone number',
  'shop-config.bill.phoneShop.placeholder': 'Enter the store phone number',
  'shop-config.bill.addressShop': 'Shop address',
  'shop-config.bill.addressShop.placeholder': 'Enter the address',
  'shop-config.bill.footer': 'Information under invoice',
  'shop-config.bill.footer.placeholder': 'Enter information under invoice',
  'shop-config.image-input-add.title': 'Add image',

  'shop-config.logo': 'Change logo',
  'shop-image-login.logo': 'Change image login',

  //
  'shop-config.image-popup': 'Change image popup',
  'shop-config.image-popup.title': 'Image',
  'shop-config.image-popup-input-add.title': 'Add image',
  'shop-config.button': 'Delete all',

  // product
  'shop-config.product': 'Product configuration',
  'shop-config.product.expiry': 'Number of days is about to expire',
  'shop-config.product.expiry.placeholder': 'Enter the number of days is about to expire',
  'shop-config.product.number': 'The number of products is about to run out of stock',
  'shop-config.product.number.placeholder': 'Enter the number of products is about to run out of stock',
};

const usLangShop = {
  ...shop,
  ...config,
};

export default usLangShop;
