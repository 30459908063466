const category = {
  'category.create': 'Thêm danh mục sản phẩm',
  'category.list': 'Danh sách danh mục sản phẩm',
  'category.edit': 'Cập nhật danh mục sản phẩm',
  'category.delete': 'Xóa danh mục sản phẩm',
  'category.manage': 'Quản lý danh mục sản phẩm',
  'category.create.confirm': 'Xác nhận tạo danh mục sản phẩm',
  'category.create.confirmSure': 'Bạn chắc chắn tạo danh mục sản phẩm?',
  'category.edit.confirm': 'Xác nhận cập nhật danh mục sản phẩm',
  'category.edit.confirmSure': 'Bạn chắc chắn cập nhật danh mục sản phẩm?',
  'category.create.success': 'Tạo danh mục sản phẩm thành công',
  'category.update.success': 'Cập nhật danh mục sản phẩm thành công',
  'category.delete.confirm': 'Xác nhận xóa danh mục sản phẩm',
  'category.delete.confirmSure': 'Bạn chắc chắn xóa danh mục sản phẩm?',
  'category.delete.success': 'Xóa danh mục sản phẩm thành công',
};

const vnLangCategory = {
  ...category,
};

export default vnLangCategory;
