const productLookup = {
  'product-lookup.helmet': 'Search product',
  'product-lookup.title': 'Search product',
  'product-lookup.name': 'Product name',
  'product-lookup.code': 'Product code',
  'product-lookup.unit': 'Unit',
  'product-lookup.price.capital': 'Import price',
  'product-lookup.price.sale': 'Price',
  'product-lookup.inventory': 'Inventory',
  'product-lookup.inventory.nQuantity': 'No number manage',
  'product-lookup.expire': 'Expiry',
  'product-lookup.image': 'Image',
};

const usLangProductLookup = {
  ...productLookup,
};

export default usLangProductLookup;
