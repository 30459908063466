const unit = {
  'unit.create': 'Thêm đơn vị tính',
  'unit.list': 'Danh sách đơn vị tính',
  'unit.edit': 'Cập nhật đơn vị tính',
  'unit.delete': 'Xóa đơn vị tính',
  'unit.manage': 'Quản lý đơn vị tính',
  'unit.create.confirm': 'Xác nhận tạo đơn vị tính',
  'unit.create.confirmSure': 'Bạn chắc chắn tạo đơn vị tính?',
  'unit.edit.confirm': 'Xác nhận cập nhật đơn vị tính',
  'unit.edit.confirmSure': 'Bạn chắc chắn cập nhật đơn vị tính?',
  'unit.create.success': 'Tạo đơn vị tính thành công',
  'unit.update.success': 'Cập nhật đơn vị tính thành công',
  'unit.delete.confirm': 'Xác nhận xóa đơn vị tính',
  'unit.delete.confirmSure': 'Bạn chắc chắn xóa đơn vị tính?',
  'unit.delete.success': 'Xóa đơn vị tính thành công',
};

const vnLangUnit = {
  ...unit,
};

export default vnLangUnit;
