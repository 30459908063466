const workplace = {
  //
  'dashboard.workplace.radius': 'Radius',
  'dashboard.workplace.name': 'Name work place',
  'dashboard.workplace.place': 'Place',
  'dashboard.workplace.ipAddress': 'IP Address',
  'dashboard.workplace.note': 'Note',
  'dashboard.workplace.manager': 'Work place manager',
  'dashboard.workplace.list': 'Work place list',
  'dashboard.workplace.add': 'Add work place',
  'dashboard.workplace.edit': 'Edit work place',
  'dashboard.workplace.delete': 'Delete work place',
  'dashboard.workplace.confirm-add': 'Confirm add work place',
  'dashboard.workplace.confirm-sure-add': 'You will definitely add this work place?',
  'dashboard.workplace.confirm-edit': 'Confirm edit work place',
  'dashboard.workplace.confirm-sure-edit': 'You will definitely edit this work place?',
  'dashboard.workplace.confirm-delete': 'Confirm delete work place',
  'dashboard.workplace.confirm-sure-delete': 'You will definitely delete this work place?',
  // form
  'dashboard.workplace.longitude': 'Longitude',
  'dashboard.workplace.latitude': 'Latitude',
  'dashboard.workplace.name-require': 'Please enter name work place',
  'dashboard.workplace.longitude-require': 'Please enter longitude',
  'dashboard.workplace.latitude-require': 'Please enter latitude',

  'dashboard.workTracking.day': 'Day',
  'dashboard.workTracking.hours': 'Hours',
  'workTracking.notWorking': 'Not working',
  'dashboards.revenue': 'Revenue',

  // notification
  'dashboard.workplace.addSuccess': 'Add work place success',
  'dashboard.workplace.addFailure': 'Add work place failure',
  'dashboard.workplace.editSuccess': 'Edit work place success',
  'dashboard.workplace.editFailure': 'Edit work place failure',
  'dashboard.workplace.deleteSuccess': 'Delete work place success',
  'dashboard.workplace.deletedFailure': 'Delete work place failure',

  'dashboard.this-day': 'Today',
  'dashboard.this-week': 'Week',
  'dashboard.this-month': 'Month',
  'dashboard.this-year': 'Year',
  'dashboard.revenue-number': 'Sales chart',
  'dashboard.revenue-export': 'Export chart',
  'dashboard.revenue-grow': 'The growth chart',

  'dashboard.warehouse.will-outInStock': 'Almost out in stock',
  'dashboard.warehouse.inventory': 'Inventory',
  'dashboard.revenue-grow.revenue': 'Revenue',
  'dashboard.revenue-grow.number': 'Number',
  'dashboard.order-export': 'Number order',
  'dashboard.helmet-title': 'Dashboard',
};

const schedule = {
  //
  'dashboard.schedule.code': 'ID',
  'dashboard.schedule.date': 'Date',
  'dashboard.schedule.time': 'Time',
  'dashboard.schedule.note': 'Note',
  'dashboard.schedule.reason': 'Reason',
  'dashboard.schedule.reason.require': 'Please enter your reason',
  'dashboard.schedule.status': 'Status',
  'dashboard.schedule.businessList': 'List business schedule',
  'dashboard.schedule.manager': 'Manager calendar',
  'dashboard.schedule.add': 'Add business schedule',
  'dashboard.schedule.confirm-add': 'Confirm add business schedule',
  'dashboard.schedule.confirm-sure-add': 'Are you sure to add this business schedule?',
  'dashboard.schedule.confirm-edit': 'Confirm edit business schedule',
  'dashboard.schedule.confirm-sure-edit': 'Are you sure to edit this business schedule?',
  'dashboard.schedule.confirm-delete': 'Confirm delete business schedule',
  'dashboard.schedule.confirm-sure-delete': 'Are you sure to delete this business schedule?',
  'dashboard.schedule.accept': 'Accept work schedule',
  'dashboard.schedule.acceptFailure': 'Accept work schedule failure',

  // form
  'dashboard.schedule.employee': 'Employee',
  'dashboard.schedule.employee-require': 'Please select a staff member',
  'dashboard.schedule.place': 'Place',
  'dashboard.schedule.place-require': 'Please choose a place to work',
  'dashboard.schedule.date-start': 'Start day',
  'dashboard.schedule.date-start-require': 'Please select a start day',
  'dashboard.schedule.date-end': 'End day',
  'dashboard.schedule.date-end-require': 'Please select a end day',
  'dashboard.schedule.time-from': 'Time from',
  'dashboard.schedule.time-from-require': 'Please select a start time',
  'dashboard.schedule.time-to': 'Time to',
  'dashboard.schedule.time-to-require': 'Please select a end time',
  // notification

  'dashboard.schedule.addSuccess': 'Add business schedule success',
  'dashboard.schedule.addFailure': 'Add business schedule failure',
  'dashboard.schedule.editSuccess': 'Edit business schedule success',
  'dashboard.schedule.editFailure': 'Edit business schedule failure',
  'dashboard.schedule.deleteSuccess': 'Delete business schedule success',
  'dashboard.schedule.deletedFailure': 'Delete business schedule failure',

  // errors
  'dashboard.schedule.timeStart': 'The start date cannot be before today',
  'dashboard.schedule.timeEnd': 'The end date cannot be before the start date',
  'dashboard.schedule.timeEndLarger': 'The end time cannot be before the start time',
  'dashboard.schedule.timeExpiryLarger': 'The expiry time cannot be before the end time',
};

const vacation = {
  //
  'dashboard.vacation.list': 'List vacation schedule',
  'dashboard.vacation.list.employee': 'Statistic of employee',
  'dashboard.vacation.manager': 'Manager vacation schedule',
  'dashboard.vacation.add': 'Add vacation schedule',
  'dashboard.vacation.edit': 'Edit vacation schedule',
  'dashboard.vacation.confirm-add': 'Confirm add vacation schedule',
  'dashboard.vacation.confirm-sure-add': 'Are you sure to add this vacation schedule?',
  'dashboard.vacation.confirm-edit': 'Confirm edit vacation schedule',
  'dashboard.vacation.confirm-sure-edit': 'Are you sure to edit this vacation schedule?',
  'dashboard.vacation.accept': 'Accept work schedule',
  'dashboard.vacation.acceptFailure': 'Accept work schedule failure',
  'dashboard.vacation.paidLeave': 'Paid leave',
  'dashboard.vacation.unPaidLeave': 'Unpaid leave',
  'dashboard.vacation.book': 'Book vacation',
  'dashboard.vacation.user-backup': 'User substitute',
  'dashboard.vacation.name': 'Name',
  // notification
  'dashboard.vacation.addSuccess': 'Add vacation schedule success',
  'dashboard.vacation.bookSuccess': 'Book vacation schedule success',
  'dashboard.vacation.addFailure': 'Add vacation schedule failure',
  'dashboard.vacation.editSuccess': 'Edit vacation schedule success',
  'dashboard.vacation.editFailure': 'Edit vacation schedule failure',
  'dashboard.vacation.deleteSuccess': 'Delete vacation schedule success',
  'dashboard.vacation.deletedFailure': 'Delete vacation schedule failure',

  'dashboard.vacation.pending': 'Pending',
  'dashboard.vacation.noPaid': 'Unpaid leave',
  'dashboard.vacation.paid': 'Paid leave',
};

const workTracking = {
  //
  'dashboard.workTracking.name': 'Name shipping',
  'dashboard.workTracking.employee': 'Employee',
  'dashboard.workTracking.place': 'Place',
  'dashboard.workTracking.time': 'Time',
  'dashboard.workTracking.list': 'Log time work tracking',
  'dashboard.workTracking.manager': 'Manager work tracking',
  'dashboard.workTracking.export': 'Export report',
};

const notification = {
  'dashboard.notification.list': 'List notification',
  'dashboard.notification.unRead': 'Notification unread',
  'dashboard.notification.order': 'Order notification',
  'dashboard.notification.notice': 'Notice',
  'dashboard.notification.time': 'Time',
  'dashboard.notification.content': 'Content notification',
  'dashboard.notification.system': 'System notification',
};

const loginPage = {
  'auth.login.hetmet': 'Login page',
  'auth.login.title': 'Login page',
  'auth.login.sub-title-1': 'Login with your credential to',
  'auth.login.sub-title-2': 'access your account!',
  'auth.login.btn-login': 'Log in',
  'auth.login.err-msg': 'Your email or password is wrong. Please check again.',
  'common.label.email': 'Email (username)',
  'common.label.password': 'Password',
  'common.label.captcha': 'Captcha',
  'auth.login.btn-login-google': 'Log in with Google',
  'auth.login.btn-login-staff': 'Log in as Staff',
  'common.label.Or': 'Or',
  'auth.login.not-account': 'Not account?',
  'auth.login.label-register': 'Register',
  'auth.login.label-forget-password': 'Forget password?',
};

const RegisterPage = {
  'auth.register.hetmet': 'Sign up account',
  'auth.register.title': 'Sign up account',
  'auth.register.btn-register': 'Sign up',
  'auth.register.err-msg': 'Your registration information is incorrect. Please check again.',
  'auth.register.has-account': 'Do you already have an account?',
  'auth.register.label-login': 'Log in',
  'common.label.shopName': 'Shop name',
  'common.label.phone': 'Phone(Zalo)',
  'common.label.email': 'Email',
  'common.label.address': 'Address',
  'common.label.captcha': 'Security Code ',
  'common.label.branch': 'industry',
};
const ForgetPasswordPage = {
  'auth.forgetPassword.title': 'FORGET PASSWORD',
  'auth.forgetPassword.change-password-title': 'CHANGE PASSWORD',
  'auth.otp-confirm-title': 'OTP CONFIRM',
  'auth.forgetPassword.err-msg': 'Password and confirmation password do not match. Please check again.',
  'auth.forgetPassword.form-pass-err-msg': 'Password must be at least 5 characters and contain letters and numbers. Please check again.',
  'auth.forgetPassword.email.notfound.err-msg': 'Email is incorrect. Please check again.',
  'auth.forgetPassword.otp.err-msg': 'OTP is incorrect. Please check again',
  'auth.forgetPassword.placeholder': 'New password',
  'auth.forgetPassword.placeholder.email': 'Enter email',
  'auth.forgetPassword.placeholder.otp': 'Encode OTP',
  'auth.forgetPassword.placeholder.otp-required': 'Enter encode OTP',
  'auth.forgetPassword.placeholder.sent-otp-later': 'You have not received the OTP code yet? Resend later',
  'auth.forgetPassword.placeholder-required': 'Enter new password',
  'auth.forgetPassword.notify.success.title': 'SUCCESS',
  'auth.forgetPassword.notify.success': 'Change password success',
  'auth.forgetPassword.notify.check-email': 'Check email',
  'auth.forgetPassword.notify.check-email-to-change': 'to get the change code',
  'auth.forgetPassword.otp.is.value-in-time': 'OTP code is valid in',
  'auth.forgetPassword.notify.check-email-zalo': 'Please check Zalo/Email to receive the OTP code to activate your account',
  'auth.forgetPassword.email-required': 'Enter the email address registered with Satek',

};

const chart = {
  'dashboard.chart.products-best-selling': 'List of best -selling products',
  'dashboard.chart.revenue': 'Revenue',
  'dashboard.chart.revenue.today': 'Revenue today',
  'dashboard.chart.revenue.today.order.count': 'Application for sale',
  'dashboard.chart.revenue.today.order.number': 'Number of products sold',
  'dashboard.chart.revenue.today.order.total': 'Total',
  'dashboard.chart.revenue.today.order.decrease': 'Sum reduction',
  'dashboard.chart.revenue.today.return.count': 'Paid application',
  'dashboard.chart.revenue.today.return.number': 'Number of products paid',
  'dashboard.chart.revenue.today.return.money': 'Total refund',
  'dashboard.chart.products-expiry': 'The product is about to expire',
  'dashboard.chart.products-almost-outInStock': 'The product is about to run out of stock',
  'dashboard.redirect.product-lookup': 'Switch to inventory page',
  // field

  'dashboard.product.name': 'Products name',
  'dashboard.product.number': 'Quantity',
  'dashboard.product.expiry': 'Expiry',
  'dashboard.error.nothingFind': 'No information found',

};

const usLangDashboard = {
  ...workplace,
  ...schedule,
  ...vacation,
  ...workTracking,
  ...notification,
  ...loginPage,
  ...chart,
  ...RegisterPage,
  ...ForgetPasswordPage,
};

export default usLangDashboard;
