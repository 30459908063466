// eslint-disable-next-line import/no-extraneous-dependencies
import { handleActions } from 'redux-actions';

// eslint-disable-next-line import/no-extraneous-dependencies
import { handleUpdateDataList } from '~/helpers/utils';
import * as Actions from './actions';

const initialState = {
  // Get All ShopMember
  isFirstGetAllShopMembers: false,
  isGetAllShopMembersRequest: false,
  isGetAllShopMembersSuccess: false,
  isGetAllShopMembersFailure: false,
  getAllShopMembersState: {},
  // Create ShopMember
  isCreateShopMemberRequest: false,
  isCreateShopMemberSuccess: false,
  isCreateShopMemberFailure: false,
  // Update ShopMember
  isUpdateShopMemberRequest: false,
  isUpdateShopMemberSuccess: false,
  isUpdateShopMemberFailure: false,
  // Delete ShopMember
  isDeleteShopMemberRequest: false,
  isDeleteShopMemberSuccess: false,
  isDeleteShopMemberFailure: false,
  //
  isUsePointShopMemberRequest: false,
  isUsePointShopMemberSuccess: false,
  isUsePointShopMemberFailure: false,
  // Local
  errorMessages: [],
};

const reducer = handleActions(
  {
    // #region : Get All ShopMember
    [Actions.getAllShopMembersRequest]: (state) => ({
      ...state,
      isGetAllShopMembersRequest: true,
      isGetAllShopMembersSuccess: false,
      isGetAllShopMembersFailure: false,
    }),
    [Actions.getAllShopMembersSuccess]: (state, { payload }) => ({
      ...state,
      isFirstGetAllShopMembers: true,
      isGetAllShopMembersRequest: false,
      isGetAllShopMembersSuccess: true,
      isGetAllShopMembersFailure: false,
      getAllShopMembersState: payload,
    }),
    [Actions.getAllShopMembersFailure]: (state, { payload }) => ({
      ...state,
      isGetAllShopMembersRequest: false,
      isGetAllShopMembersSuccess: false,
      isGetAllShopMembersFailure: true,
      errorMessages: payload,
    }),
    // #endregion
    // #region : Create ShopMember
    [Actions.createShopMemberRequest]: (state) => ({
      ...state,
      isCreateShopMemberRequest: true,
      isCreateShopMemberSuccess: false,
      isCreateShopMemberFailure: false,
    }),
    [Actions.createShopMemberSuccess]: (state, { payload }) => {
      const getAllShopMembersStateTmp = handleUpdateDataList(state.getAllShopMembersState, payload.data, 'create');

      return ({
        ...state,
        isCreateShopMemberRequest: false,
        isCreateShopMemberSuccess: true,
        isCreateShopMemberFailure: false,
        getAllShopMembersState: { ...getAllShopMembersStateTmp },
      });
    },
    [Actions.createShopMemberFailure]: (state, { payload }) => ({
      ...state,
      isCreateShopMemberRequest: false,
      isCreateShopMemberSuccess: false,
      isCreateShopMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetCreateShopMember]: (state) => ({
      ...state,
      isCreateShopMemberRequest: false,
      isCreateShopMemberSuccess: false,
      isCreateShopMemberFailure: false,
    }),
    // #endregion
    // #region : Update ShopMember
    [Actions.updateShopMemberRequest]: (state) => ({
      ...state,
      isUpdateShopMemberRequest: true,
      isUpdateShopMemberSuccess: false,
      isUpdateShopMemberFailure: false,
    }),
    [Actions.updateShopMemberSuccess]: (state, { payload }) => {
      const getAllShopMembersStateTmp = handleUpdateDataList(state.getAllShopMembersState, payload.data, 'update');

      return ({
        ...state,
        isUpdateShopMemberRequest: false,
        isUpdateShopMemberSuccess: true,
        isUpdateShopMemberFailure: false,
        getAllShopMembersState: { ...getAllShopMembersStateTmp },
      });
    },
    [Actions.updateShopMemberFailure]: (state, { payload }) => ({
      ...state,
      isUpdateShopMemberRequest: false,
      isUpdateShopMemberSuccess: false,
      isUpdateShopMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUpdateShopMember]: (state) => ({
      ...state,
      isUpdateShopMemberRequest: false,
      isUpdateShopMemberSuccess: false,
      isUpdateShopMemberFailure: false,
    }),
    // #endregion
    // #region : Delete ShopMember
    [Actions.deleteShopMemberRequest]: (state) => ({
      ...state,
      isDeleteShopMemberRequest: true,
      isDeleteShopMemberSuccess: false,
      isDeleteShopMemberFailure: false,
    }),
    [Actions.deleteShopMemberSuccess]: (state, { payload }) => {
      const getAllShopMembersStateTmp = handleUpdateDataList(state.getAllShopMembersState, payload, 'delete');

      return ({
        ...state,
        isDeleteShopMemberRequest: false,
        isDeleteShopMemberSuccess: true,
        isDeleteShopMemberFailure: false,
        getAllShopMembersState: { ...getAllShopMembersStateTmp },
      });
    },
    [Actions.deleteShopMemberFailure]: (state, { payload }) => ({
      ...state,
      isDeleteShopMemberRequest: false,
      isDeleteShopMemberSuccess: false,
      isDeleteShopMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetDeleteShopMember]: (state) => ({
      ...state,
      isDeleteShopMemberRequest: false,
      isDeleteShopMemberSuccess: false,
      isDeleteShopMemberFailure: false,
    }),
    // #endregion

    // #region Use point
    [Actions.usePointShopMemberRequest]: (state) => ({
      ...state,
      isUsePointShopMemberRequest: true,
      isUsePointShopMemberSuccess: false,
      isUsePointShopMemberFailure: false,
    }),
    [Actions.usePointShopMemberSuccess]: (state) => ({
      ...state,
      isUsePointShopMemberRequest: false,
      isUsePointShopMemberSuccess: true,
      isUsePointShopMemberFailure: false,
    }),
    [Actions.usePointShopMemberFailure]: (state, { payload }) => ({
      ...state,
      isUsePointShopMemberRequest: false,
      isUsePointShopMemberSuccess: false,
      isUsePointShopMemberFailure: true,
      errorMessages: payload,
    }),
    [Actions.resetUsePointShopMember]: (state) => ({
      ...state,
      isUsePointShopMemberRequest: false,
      isUsePointShopMemberSuccess: false,
      isUsePointShopMemberFailure: false,
      errorMessages: [],
    }),
    // #endregion

    // #region : Local
    [Actions.resetShopMemberState]: () => initialState,
    // #endregion
  },
  initialState,
);

export default reducer;
