const vnLangWarehouse = {
  'warehouse.helmet': 'Danh sách kho',
  'warehouse.breadcrumb.title': 'Danh sách kho',
  'warehouse.breadcrumb.parent': 'Bán hàng',
  'warehouse.modal.create.title': 'Thêm kho',
  'warehouse.modal.create.confirm.title': 'Xác nhận thêm kho mới',
  'warehouse.modal.create.confirm.description': 'Bạn chắc chắn muốn thêm kho này?',
  'warehouse.modal.detail.title': 'Thông tin chi tiết kho',
  'warehouse.modal.update.title': 'Cập nhật thông tin kho',
  'warehouse.modal.update.confirm.title': 'Xác nhận cập nhật thông tin kho',
  'warehouse.modal.update.confirm.description': 'Bạn chắc chắn muốn cập nhật thông tin kho này?',
  'warehouse.modal.delete.title': 'Xác nhận xoá kho',
  'warehouse.modal.delete.description': 'Bạn chắc chắn muốn xoá kho này?',
  'warehouse.modal.reset-password.title': 'Đặt lại mật khẩu',
  'warehouse.noti.create.success': 'Thêm kho thành công!',
  'warehouse.noti.create.failure': 'Thêm kho không thành công! Vui lòng thử lại sau.',
  'warehouse.noti.update.success': 'Cập nhật kho thành công!',
  'warehouse.noti.update.failure': 'Cập nhật kho không thành công! Vui lòng thử lại sau.',
  'warehouse.noti.delete.success': 'Xoá kho thành công!',
  'warehouse.noti.delete.failure': 'Xoá kho không thành công! Vui lòng thử lại sau.',
  'warehouse.noti.reset-password.success': 'Đặt lại mật khẩu kho thành công!',
  'warehouse.noti.reset-password.failure': 'Đặt lại mật khẩu kho không thành công! Vui lòng thử lại sau.',
  'warehouse.btn-create': 'Thêm',
  'warehouse.error-min-length': 'Tối thiểu $x ký tự!',
  'warehouse.error-max-length': 'Tối đa $x ký tự!',
  'warehouse.code.col': 'Mã kho',
  'warehouse.code.label': 'Mã kho',
  'warehouse.code.placeholder': 'Nhập mã kho',
  'warehouse.barcode.col': 'Mã vạch',
  'warehouse.barcode.label': 'Mã vạch',
  'warehouse.barcode.placeholder': 'Nhập mã vạch',
  'warehouse.categories.col': 'Dòng kho',
  'warehouse.categories.label': 'Dòng kho',
  'warehouse.categories.placeholder': 'Chọn dòng kho',
  'warehouse.categories.error.required': 'Vui lòng chọn dòng kho!',
  'warehouse.name.col': 'Tên kho',
  'warehouse.name.label': 'Tên kho',
  'warehouse.name.placeholder': 'Nhập tên kho',
  'warehouse.name.error.required': 'Vui lòng nhập tên kho!',
  'warehouse.desc.col': 'Mô tả',
  'warehouse.desc.label': 'Mô tả kho',
  'warehouse.desc.placeholder': 'Nhập mô tả kho',
  'warehouse.unit.col': 'Đơn vị',
  'warehouse.unit.label': 'Đơn vị tính cơ bản',
  'warehouse.unit.placeholder': 'Nhập đơn vị tính cơ bản',
  'warehouse.unit.error-required': 'Vui lòng nhập đơn vị tính cơ bản!',
  'warehouse.price.col': 'Giá bán',
  'warehouse.price.label': 'Giá bán (vnđ)',
  'warehouse.price.placeholder': 'Nhập giá bán',
  'warehouse.price.error-required': 'Vui lòng nhập giá bán!',
  'warehouse.price.invalid': 'Nhập giá lớn hơn 0!',
  'warehouse.units.name.label': 'Tên đơn vị',
  'warehouse.units.name.placeholder': 'Nhập tên đơn vị',
  'warehouse.units.name.error-required': 'Vui lòng nhập tên đơn vị!',
  'warehouse.units.number.label': 'Giá trị quy đổi',
  'warehouse.units.number.placeholder': 'Nhập giá trị quy đổi',
  'warehouse.units.number.error-required': 'Vui lòng nhập giá trị quy đổi!',
  'warehouse.units.number.error-invalid': 'Giá trị quy đổi phải lớn hơn 0!',
  'warehouse.units.price.label': 'Giá bán (vnđ)',
  'warehouse.units.price.placeholder': 'Nhập giá bán',
  'warehouse.units.price.error-required': 'Vui lòng nhập giá bán!',
  'warehouse.units.code.label': 'Mã kho',
  'warehouse.units.code.placeholder': 'Nhập mã kho',
  'warehouse.units.barcode.label': 'Mã vạch',
  'warehouse.units.barcode.placeholder': 'Nhập mã vạch',
  'warehouse.image.col': 'Hình ảnh',
  'warehouse.image.label': 'Tải lên',
  'warehouse.image.label-2': 'Hình ảnh kho',
  'warehouse.image.error-required': 'Vui lòng tải lên ảnh kho!',
  'warehouse.btn-add-units-items': 'Thêm đơn vị tính',
  'warehouse.email.validate': 'Email không đúng định dạng',
  'warehouse.phone.label': 'Số điện thoại kho',
  'warehouse.phone.placeholder': 'Nhập số điện thoại kho',
  'warehouse.email.label': 'Email kho',
  'warehouse.email.placeholder': 'Nhập email kho',
  'warehouse.address.label': 'Địa chỉ kho',
  'warehouse.address.placeholder': 'Nhập địa chỉ kho',
  'warehouse.address.work-place': 'Địa điểm',
};

export default vnLangWarehouse;
