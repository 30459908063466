import vnLangUnit from './Unit/vn';
import vnLangCategory from './Category/vn';
import vnLangSupplier from './Supplier/vn';
import vnLangWarehouse from './Warehouse/vn';
import vnLangActionWarehouse from './ActionWarehouse/vn';
import vnLangDiscount from './Discount/vn';
import vnLangProductLookup from './SearchProduct/vn';
import vnLangTableGroup from './TableGroup/vn';
import vnLangTable from './Table/vn';
import vnLangKitchen from './Kitchen/vn';
import vnLangPackageBought from './PackageBought/vn';
import vnLangPromotion from './Promotion/vn';
import vnLangCancelOrder from './CancelOrder/vn';

const vnLangSell = {
  ...vnLangUnit,
  ...vnLangCategory,
  ...vnLangSupplier,
  ...vnLangWarehouse,
  ...vnLangActionWarehouse,
  ...vnLangDiscount,
  ...vnLangProductLookup,
  ...vnLangTableGroup,
  ...vnLangTable,
  ...vnLangKitchen,
  ...vnLangPackageBought,
  ...vnLangPromotion,
  ...vnLangCancelOrder,
};

export default vnLangSell;
