const workTime = {
  'work.time': 'Ca làm',
  'work.time.list': 'Danh sách ca làm việc',
  'work.time.edit': 'Cập nhật ca làm việc',
  'work.time.delete': 'Xóa ca làm việc',
  'work.time.manage': 'Quản lý ca làm',
  'work.time.create': 'Tạo ca làm việc',
  'work.time.create.confirm': 'Xác nhận tạo ca làm việc',
  'work.time.create.confirmSure': 'Bạn chắc chắn tạo ca làm việc?',
  'work.time.create.success': 'Tạo ca làm việc thành công',
  'work.time.edit.confirm': 'Xác nhận cập nhật ca làm việc',
  'work.time.edit.confirmSure': 'Bạn chắc chắn cập nhật ca làm việc?',
  'work.time.update.success': 'Cập nhật ca làm việc thành công',
  'work.time.delete.confirm': 'Xác nhận xóa ca làm việc',
  'work.time.delete.confirmSure': 'Bạn chắc chắn xóa ca làm việc?',
  'work.time.delete.success': 'Xóa ca làm việc thành công',
  'work.time.method': 'Hình thức',
  'work.time.full-day': 'Cả ngày',
  'work.time.half-day': 'Nữa ngày',
  'work.time.part-time': 'Bán thời gian',
  'work.time.next': 'Qua ngày hôm sau',
};

const vnLangWorkTime = {
  ...workTime,
};

export default vnLangWorkTime;
