// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllDiscountsRequest = createAction('GET_ALL_DISCOUNTS_REQUEST');
export const getAllDiscountsSuccess = createAction('GET_ALL_DISCOUNTS_SUCCESS');
export const getAllDiscountsFailure = createAction('GET_ALL_DISCOUNTS_FAILURE');

export const getCustomerVoucherRequest = createAction('GET_CUSTOMER_VOUCHER_REQUEST');
export const getCustomerVoucherSuccess = createAction('GET_CUSTOMER_VOUCHER_SUCCESS');
export const getCustomerVoucherFailure = createAction('GET_CUSTOMER_VOUCHER_FAILURE');
export const resetCustomerVoucherState = createAction('RESET_CUSTOMER_VOUCHER_STATE');

export const createDiscountRequest = createAction('CREATE_DISCOUNT_REQUEST');
export const createDiscountSuccess = createAction('CREATE_DISCOUNT_SUCCESS');
export const createDiscountFailure = createAction('CREATE_DISCOUNT_FAILURE');
export const resetCreateDiscount = createAction('RESET_CREATE_DISCOUNT');

export const getAllDiscountsProductRequest = createAction('GET_ALL_DISCOUNTS_PRODUCT_REQUEST');
export const getAllDiscountsProductSuccess = createAction('GET_ALL_DISCOUNTS_PRODUCT_SUCCESS');
export const getAllDiscountsProductFailure = createAction('GET_ALL_DISCOUNTS_PRODUCT_FAILURE');

export const createDiscountProductRequest = createAction('CREATE_DISCOUNT_PRODUCT_REQUEST');
export const createDiscountProductSuccess = createAction('CREATE_DISCOUNT_PRODUCT_SUCCESS');
export const createDiscountProductFailure = createAction('CREATE_DISCOUNT_PRODUCT_FAILURE');
export const resetCreateDiscountProduct = createAction('RESET_CREATE_DISCOUNT_PRODUCT');

export const updateDiscountRequest = createAction('UPDATE_DISCOUNT_REQUEST');
export const updateDiscountSuccess = createAction('UPDATE_DISCOUNT_SUCCESS');
export const updateDiscountFailure = createAction('UPDATE_DISCOUNT_FAILURE');
export const resetUpdateDiscount = createAction('RESET_UPDATE_DISCOUNT');

export const updateDiscountProductRequest = createAction('UPDATE_DISCOUNT_PRODUCT_REQUEST');
export const updateDiscountProductSuccess = createAction('UPDATE_DISCOUNT_PRODUCT_SUCCESS');
export const updateDiscountProductFailure = createAction('UPDATE_DISCOUNT_PRODUCT_FAILURE');
export const resetUpdateDiscountProduct = createAction('RESET_UPDATE_PRODUCT_DISCOUNT');

export const deleteDiscountRequest = createAction('DELETE_DISCOUNT_REQUEST');
export const deleteDiscountSuccess = createAction('DELETE_DISCOUNT_SUCCESS');
export const deleteDiscountFailure = createAction('DELETE_DISCOUNT_FAILURE');
export const resetDeleteDiscount = createAction('RESET_DELETE_DISCOUNT');

export const deleteDiscountProductRequest = createAction('DELETE_DISCOUNT_PRODUCT_REQUEST');
export const deleteDiscountProductSuccess = createAction('DELETE_DISCOUNT_PRODUCT_SUCCESS');
export const deleteDiscountProductFailure = createAction('DELETE_DISCOUNT_PRODUCT_FAILURE');
export const resetDeleteDiscountProduct = createAction('RESET_DELETE_DISCOUNT_PRODUCT');

export const resetDiscountState = createAction('RESET_DISCOUNT_STATE');
