const unit = {
  'unit.list': 'List unit',
  'unit.edit': 'Edit unit',
  'unit.delete': 'Delete unit',
  'unit.manage': 'Manage unit',
  'unit.create': 'Create unit',
  'unit.create.confirm': 'Confirm create unit',
  'unit.create.confirmSure': 'You will definitely create unit?',
  'unit.create.success': 'Create unit successfully',
  'unit.edit.confirm': 'Confirm edit unit',
  'unit.edit.confirmSure': 'You will definitely edit unit?',
  'unit.delete.confirm': 'Confirm delete unit',
  'unit.delete.confirmSure': 'You will definitely delete unit?',
  'unit.delete.success': 'Update unit successfully',
  'unit.update.success': 'Update unit successfully',
};

const usLangUnit = {
  ...unit,
};

export default usLangUnit;
