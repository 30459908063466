// eslint-disable-next-line import/no-extraneous-dependencies
import { createAction } from 'redux-actions';

export const getAllPromotionRequest = createAction('GET_ALL_PROMOTION_REQUEST');
export const getAllPromotionSuccess = createAction('GET_ALL_PROMOTION_SUCCESS');
export const getAllPromotionFailure = createAction('GET_ALL_PROMOTION_FAILURE');

export const createPromotionRequest = createAction('CREATE_PROMOTION_REQUEST');
export const createPromotionSuccess = createAction('CREATE_PROMOTION_SUCCESS');
export const createPromotionFailure = createAction('CREATE_PROMOTION_FAILURE');
export const resetCreatePromotion = createAction('RESET_CREATE_PROMOTION');

export const updatePromotionRequest = createAction('UPDATE_PROMOTION_REQUEST');
export const updatePromotionSuccess = createAction('UPDATE_PROMOTION_SUCCESS');
export const updatePromotionFailure = createAction('UPDATE_PROMOTION_FAILURE');
export const resetUpdatePromotion = createAction('RESET_UPDATE_PROMOTION');

export const deletePromotionRequest = createAction('DELETE_PROMOTION_REQUEST');
export const deletePromotionSuccess = createAction('DELETE_PROMOTION_SUCCESS');
export const deletePromotionFailure = createAction('DELETE_PROMOTION_FAILURE');
export const resetDeletePromotion = createAction('RESET_DELETE_PROMOTION');

export const resetPromotionState = createAction('RESET_PROMOTION_STATE');
