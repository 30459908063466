const usLangCustomer = {
  'customer.helmet': 'List of customers',
  'customer.breadcrumb.title': 'List of customers',
  'customer.breadcrumb.parent': 'User management',
  'customer.modal.create.title': 'More customers',
  'customer.modal.create.confirm.title': 'Confirm new customers',
  'customer.modal.create.confirm.description': 'You definitely want to add this customer?',
  'customer.modal.detail.title': 'Customer details',
  'customer.modal.update.title': 'Update customer information',
  'customer.modal.update.confirm.title': 'Confirm customer information update',
  'customer.modal.update.confirm.description': 'You definitely want to update this customer information?',
  'customer.modal.delete.title': 'Customer deletion confirmation',
  'customer.modal.delete.description': 'You definitely want to delete this customer?',
  'customer.modal.reset-password.title': 'Reset Password',
  'customer.noti.create.success': 'Add successful customers!',
  'customer.noti.create.failure': 'Add unsuccessful customers!Please try again later.',
  'customer.noti.update.success': 'Update customers successfully!',
  'customer.noti.update.failure': 'Update customers unsuccessful!Please try again later.',
  'customer.noti.delete.success': 'Delete customers successfully!',
  'customer.noti.delete.failure': 'Delete customers without success!Please try again later.',
  'customer.noti.reset-password.success': 'Retite customer password successfully!',
  'customer.noti.reset-password.failure': 'Reset customer password successfully!Please try again later.',
  'customer.btn-create': 'More',
  'customer.name.col': 'First and last name',
  'customer.name.label': 'First and last name',
  'customer.name.placeholder': 'Enter the full name',
  'customer.name.error-required': 'Please enter the full name!',
  'customer.name.error-min-length': 'Minimum $x character!',
  'customer.name.error-max-length': 'Maximum $x character!',
  'customer.phone.col': 'Phone number',
  'customer.phone.label': 'Phone number',
  'customer.phone.placeholder': 'Enter your phone number',
  'customer.phone.error-required': 'Please enter the phone number!',
  'customer.phone.error-invalid': 'Invalid phone number!',
  'customer.email.col': 'Gmail',
  'customer.email.label': 'Gmail',
  'customer.email.placeholder': 'Enter Gmail',
  'customer.email.error-required': 'Please enter Gmail!',
  'customer.email.error-invalid': 'Gmail is not valid!',
  'customer.address.col': 'Address',
  'customer.address.label': 'Address',
  'customer.address.placeholder': 'Enter the address',
};

export default usLangCustomer;
