const packageTrans = {
  'package.list': 'List package',
  'package.edit': 'Edit package',
  'package.delete': 'Delete package',
  'package.manage': 'Manage package',
  'package.create': 'Create package',
  'package.create.confirm': 'Confirm create package',
  'package.create.confirmSure': 'You will definitely create package?',
  'package.create.success': 'Create package successfully',
  'package.edit.confirm': 'Confirm edit package',
  'package.edit.confirmSure': 'You will definitely edit package?',
  'package.delete.confirm': 'Confirm delete package',
  'package.delete.confirmSure': 'You will definitely delete package?',
  'package.delete.success': 'Update package successfully',
  'package.update.success': 'Update package successfully',

  'package.name': 'Service package name',
  'package.money': 'Price',
  'package.month': 'Number of months of use',
  'package.items': 'Functions',
};

const usLangConfig = {
  ...packageTrans,
};

export default usLangConfig;
